<template>
  <common-trs-page-container>
    <common-app-page-header-v2
      title="Securities"
      icon="mdi mdi-shield-lock-outline"
      :padding="0"
    />
    <div class="mx-n3 mt-5">
      <common-trs-page-nav-horizontal>
        <common-tab
          :to="{ name: 'FinancingRound' }"
          :ripple="false"
        >
          Financing Round
        </common-tab>
        <common-tab
          :to="{ name: 'ShareTerms' }"
          :ripple="false"
        >
          Security Terms
        </common-tab>
        <common-tab
          :to="{ name: 'EquityIncentivePlans' }"
          :ripple="false"
        >
          Equity Incentive Plans
        </common-tab>
        <common-tab
          :to="{ name: 'IssuedSecurities' }"
          :ripple="false"
        >
          Issued Securities
        </common-tab>
        <common-tab
          :to="{ name: 'DraftSecurities' }"
          :ripple="false"
        >
          Draft Securities
        </common-tab>
        <common-tab
          :to="{ name: 'ManageSecurities' }"
          :ripple="false"
        >
          Manage
        </common-tab>
      </common-trs-page-nav-horizontal>
    </div>
    <router-view />
  </common-trs-page-container>
</template>

<script>
  export default {
    name: 'SecuritiesIndex',
  }
</script>
